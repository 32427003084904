<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="car-models" no-body>

    <div class="page-header">
      <b-row>
        <b-col class="filtersContainer" md="12">
          <div class="filters">

            <div v-if="$checkPermission($options.name, 'insert')" class="filter-column">
              <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
            </div>

            <div class="filter-column">
              <v-select
                  @input="filterModels"
                  placeholder="Մակնիշ"
                  id="vue-select-car"
                  :reduce="prefix => prefix.id"
                  v-model="filters.car"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cars"
                  class="companyFilterSelect"
              />
            </div>

            <div class="filter-column">
              <b-form-input
                  @change="filterModels"
                  v-model="filters.name"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full opinionFilter"
                  clearable
              />
            </div>

          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        :fields="fields"
    >
      <template #cell(car)="data">
        {{ carName(data.value) }}
      </template>
      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="edit(data.item)" v-b-modal.modal-form
                 icon="edit" class="table-action-icon accent"/>
        <fa-icon v-if="$checkPermission($options.name, 'delete')" @click="deleteItem(data.item)" icon="trash-alt"
                 class="table-action-icon danger"/>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + pagination.total + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            @input="getModels"
            v-model="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.count"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        :no-close-on-backdrop="true"
        id="modal-form"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        :title="modal_title"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >
      <b-form>
        <b-form-group>
          <v-select
              placeholder="Մակնիշ"
              id="vue-select"
              :reduce="prefix => prefix.id"
              v-model="form.car"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cars"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.name"
              id="name"
              type="text"
              placeholder="Մոդել"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import carsModel from '@/store/cars/cars'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'settings-car-models',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    vSelect
  },
  data() {
    return {
      CARS_STORE_MODULE_NAME: 'carsModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'car',
          label: 'Մակնիշ',
        },
        {
          key: 'name',
          label: 'Մոդել',
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        car: ''
      },
      form: {},
      filters: {
        car: null,
        name: ''
      }
    }
  },
  computed: {

    items() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_models
    },

    pagination() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].pagination
    },

    cars() {
      const result = []
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].cars
      data.map((item) => {
        let temp = {
          id: item.id,
          label: item.name
        }
        result.push(temp)
      })
      return result
    },

    carName() {
      return (id) => {
        let name = ''
        const index = this.cars.findIndex((i) => i.id === id)
        if (index >= 0) {
          name = this.cars[index].label
        }
        return name
      }
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },

    validateForm() {
      return this.form.name === '' || this.form.car === '' || this.form.car === null
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModel)
    this.getCars()
    this.getModels()
  },
  methods: {

    getCars() {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getCars')
    },
    getModels() {
      this.$vs.loading()
      const filterData = {
        current_page: this.pagination.current_page,
        ...this.filters
      }
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getModels', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },
    filterModels () {
      this.pagination.current_page = 1
      this.getModels()
    },
    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
    },
    edit(item) {
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
    },
    deleteItem(item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել մոդելը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/deleteModel', item.id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'Մոդելը հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    changeStatus(item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' մոդելը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              item.status = status
              this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editModel', item)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    confirmForm() {
      if (this.addForm) {
        this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/addModel', this.form)
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      } else {
        this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/editModel', this.form)
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      }
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#car-models {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 16rem);
    min-height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
}
</style>


